
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import AgentReportActivityTimelineItem from "./AgentReportActivityTimelineItem.vue";
interface IAgentReportActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "AgentReportActivity",
  components: { AgentReportActivityTimelineItem },
  mixins: [ActivitiesMixin],

  data(): IAgentReportActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
            VIEW_AGENT_REPORTS_SUCCESS,
            VIEW_AGENT_REPORTS_FAILURE
            `.replace(/\s/g, ""),
        activityType: "AgentReportActivity"
      };
    }
  }
});
